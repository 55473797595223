import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav, Container } from "react-bootstrap"
import styled, { withTheme } from "styled-components"
import { link } from "fs"

interface Props {
  siteTitle: string,
  children?: any
}

const Styles = styled.div`
  font-family: optima;

  .navbar {
    background-color: #121212;
  }

  .navbar-brand, .navbar-nav .nav-link {
    color: #bbb;
    transition-duration: 0.5s;

    &:hover {
      color: white;
      transition-duration: 0.5s;
    }
  }
`

const Header = ({ siteTitle, children }: Props) => (
  <Styles>
    <Navbar expand="lg" variant="dark">
      <Container> 
        <Link to="/" className="navbar-brand" activeStyle={{color: "white"}}>{siteTitle}</Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto" activeKey="/">
            <Nav.Item><Link to="/about" className="nav-link" activeStyle={{color: "white"}}>about</Link></Nav.Item>
            <Nav.Item><Link to="/articles" className="nav-link" activeStyle={{color: "white"}}>articles</Link></Nav.Item>
            <Nav.Item><Link to="/contact" className="nav-link" activeStyle={{color: "white"}}>contact</Link></Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </Styles>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
