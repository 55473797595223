/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { ReactChildren } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "react-bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import styled from "styled-components"

import Header from "./header"
import "./layout.scss"

interface Props {
  children?: any
}

const StyledContainer = styled(Container)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  div,
  ul,
  ol {
    font-family: optima, futura, sans-serif;
  }

  main {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    height: 100%;

    h2 {
      font-size: 5rem;
    }

    h3 {
      font-size: 3rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.25rem;
    }

    p,
    ol,
    ul {
      font-size: 1.2rem;
    }

    @media screen and (max-width: 500px) {
      h2 {
        font-size: 4rem;
      }
    }
  }
`

const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      />
      <Header siteTitle={data.site.siteMetadata.title} />
      <StyledContainer>
        <main>{children}</main>
      </StyledContainer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
